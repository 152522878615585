<template>
  <div :class="customClass" :id="elementId">
    <cz-collection-manual :button-list="buttonList"></cz-collection-manual>

    <div w1200 margin-auto style="">
      <div class="video-collection-container">
        <div class="video-wrapper" v-for="(videoItem,index) in tableData" :key="videoItem.id" style="" @mouseover="handleMouseover(videoItem,index)" @mouseout="handleMouseout(videoItem,index)">
          <video-component :form-object="videoItem"></video-component>
        </div>

      </div>
      <div fc style="margin-top: 30px; margin-bottom: 30px;">
        <el-pagination
            :background="background"
            :current-page="currentPage"
            :layout="layout"
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :total="totalCount"
            cz-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

import {toMatrix}      from '@/utils/index.js'
import VideoComponent  from './components/video-component.vue'
import DataConfigMixin from '@/mixins/data-config-mixin'

export default {
  // extends   : {},
  mixins    : [DataConfigMixin],
  components: { VideoComponent },
  name      : 'VideoCollection',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      buttonList: [
        {
          name: 'VideoCollection',
          meta: {
            title: '视频集锦',
          },
        },
      ],
      ////////////////////////////////////////////////////////////////////////////
      ///////////////// 数据 //////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      tableData: [],
      ////////////////////////////////////////////////////////////////////////////
      ///////////////// 属性 //////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      currentPage: 1,
      pageSize   : 12,
      totalCount : 0,
      pageSizes  : [12, 24, 48, 60],
      layout     : 'total, prev, pager, next, sizes,jumper',
      ////////////////////////////////////////////////////////////////////////////
      ///////////////// 样式 //////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      background: false,
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    elementId() {return `VideoCollection_${this._uid}`},
    customClass() {return ['VideoCollection', this.className]},
  },
  methods : {
    handleMouseout(videoItem, index) {
      // let videoElement = this.$refs.video[index]
      // videoElement.removeAttribute('controls')
      videoItem.controls = false
    },
    handleMouseover(videoItem, index) {
      // let videoElement = this.$refs.video[index]
      // videoElement.setAttribute('controls', true)

      videoItem.controls = true
    },
    getTableData() {
      let videoCollections          = this.dataConfig.videoCollections
      videoCollections              = videoCollections.map(item => {
        // item.controls = false;
        this.$set(item, 'controls', false)
        return item
      })
      let toMatrixWonderfulPictures = toMatrix(videoCollections, this.pageSize)
      this.totalCount               = videoCollections.length
      this.tableData                = toMatrixWonderfulPictures[this.currentPage - 1]
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize    = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
  },
  created() {
    // document.documentElement.scrollTop = 0
    this.getTableData()
  },
  destroyed() {},
  mounted() {
    console.log('【video-collection:118】', `this.$store.getters.raceInformation = `, this.$store.getters.raceInformation)
    console.log('【video-collection:118】', `this.$store.getters.websiteInformation = `, this.$store.getters.websiteInformation)
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
$pageSize : 12;
$repeat   : 4;
///
$gap      : 12px;
// 16 : 9 = w / x
// 9 / 16 = x / w
// x = 9 / 16 * w
$width    : calc((1200px - #{$gap} * #{$repeat - 1}) / #{$repeat});
$height   : calc(#{$width} * 9 / 16);
//$width    : 1200px - $gap * ($repeat - 1) / $repeat;
//$height   : $width * 9 / 16;

.VideoCollection {

  .video-collection-container {
    //min-height            : calc(#{$height} * #{$pageSize / $repeat});
    //

    display               : grid;
    grid-template-columns : repeat(#{$repeat}, $width);
    gap                   : $gap;

    .video-wrapper {
      height : $height;

      .video {
        max-width  : 100%;
        max-height : 100%;
      }
    }
  }
}
</style>
